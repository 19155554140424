import { action, observable } from 'mobx'

import { theme } from '@inspectornexus/theme'

const DEFAULT_HEIGHT = 120
const mql = window.matchMedia(`(max-width: ${theme.breakpoints[1] ?? ''})`)

export class HeaderStore {
  @observable public downloadModalOpen = false

  @observable public height = mql.matches ? DEFAULT_HEIGHT - 55 : DEFAULT_HEIGHT

  public dark = false

  public preloadFuncs: Record<string, (() => void) | undefined> = {}

  public constructor() {
    mql.addListener(this.handleChangeMedia)
  }

  @action
  private readonly handleChangeMedia = () => {
    this.height = mql.matches ? DEFAULT_HEIGHT - 55 : DEFAULT_HEIGHT
  }

  @action public handleCloseDownloadModal = (): void => {
    this.downloadModalOpen = false
  }

  @action public handleOpenDownloadModal = (): void => {
    this.downloadModalOpen = true
  }

  public preloadPage = (key: string): void => {
    const thisPreloadFunction = this.preloadFuncs[key]
    if (thisPreloadFunction) {
      return thisPreloadFunction()
    }
  }

  public setPreloadFunction = (
    key: string,
    preloadFunction: () => void
  ): void => {
    this.preloadFuncs[key] = preloadFunction
  }
}

declare global {
  type IHeaderStore = HeaderStore
}
