import type { Location, LocationState, UnregisterCallback } from 'history'
import { action, computed, observable } from 'mobx'

import { notEmptyString, parseQuery } from '@inspectornexus/utils'

import type { INavStoreProps, NavStoreBaseDefinition } from './NavStore.types'

export const createNavStore = <H extends LocationState = LocationState>({
  rootStore,
  history
}: INavStoreProps<H>): NavStoreBaseDefinition => {
  class NavStore {
    private historyListener?: UnregisterCallback

    @observable public modalIsOpen = false

    @observable public currentRouteData?: ICurrentRouteData

    @computed
    public get currentHeaderVisible() {
      if (!this.currentRouteData) {
        return false
      }
      switch (this.currentRouteData.pathName) {
        case '/':
        case '/emailConfirmed':
        case '/resetPassword': {
          return false
        }

        default: {
          return true
        }
      }
    }

    @computed
    public get currentHeaderTitle() {
      if (!this.currentRouteData) {
        return undefined
      }
      const routeData = this.currentRouteData
      switch (routeData.pathName) {
        case '/dashboard': {
          return 'Dashboard'
        }
        case '/user': {
          return 'Your Profile'
        }
        case '/company': {
          return 'Your Company'
        }
        case '/inspections': {
          if (notEmptyString(routeData.query?._id)) {
            return undefined
          }
          return 'Inspections'
        }
        case '/report': {
          const thisInspectionId = routeData.query?._id
          if (notEmptyString(thisInspectionId)) {
            const thisInspection =
              rootStore.domainStore?.inspectionStore.get(thisInspectionId)
            if (thisInspection) {
              return thisInspection.nickname || 'Inspection Report'
            }
          }
          return 'Inspection Report'
        }
        case '/agents': {
          const thisAgentId = routeData.query?._id
          if (notEmptyString(thisAgentId)) {
            const thisAgent = rootStore.domainStore?.agentStore.get(thisAgentId)
            if (thisAgent) {
              return thisAgent.fullName || 'Agent'
            }
          }
          return 'Agents'
        }
        case '/templates': {
          const thisCatId = routeData.query?.catHeader
          if (notEmptyString(thisCatId)) {
            const thisCat = rootStore.domainStore?.categoryStore.get(thisCatId)
            if (thisCat) {
              return `${thisCat.label} Header`
            }
          }
          const thisTemplateId = routeData.query?._id
          if (notEmptyString(thisTemplateId)) {
            const thisTemplate =
              rootStore.domainStore?.templateStore.get(thisTemplateId)
            if (thisTemplate) {
              return thisTemplate.label || 'Template'
            }
          }
          return 'Templates'
        }

        default: {
          return undefined
        }
      }
    }

    private readonly handleLocationChange = (location: Location<H>) => {
      const query = parseQuery(location.search)
      const currentRouteData = {
        pathName: location.pathname,
        query
      } as ICurrentRouteData
      this.handleSetCurrentRouteData(currentRouteData)
    }

    @action private readonly handleSetCurrentRouteData = (
      currentRouteData: ICurrentRouteData
    ) => (this.currentRouteData = currentRouteData)

    public dispose = () => {
      if (this.historyListener) {
        this.historyListener()
      }
    }

    @action public handleSetModalIsOpen = (modalIsOpen: boolean) =>
      (this.modalIsOpen = modalIsOpen)

    public init = () => {
      this.handleLocationChange(history.location)
      this.historyListener = history.listen(this.handleLocationChange)
    }
  }
  return new NavStore()
}

declare global {
  type INavStore = NavStoreBaseDefinition
}
