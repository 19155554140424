import type { History } from 'history'
import { createBrowserHistory } from 'history'
import PouchIDB from 'pouchdb-adapter-idb'

import type { IRootStore } from '@inspectornexus/react-hooks'
import type { INexusState } from '@inspectornexus/state'
import { createNexusState } from '@inspectornexus/state'
import type { IRemoteMediaStoreProps } from '@inspectornexus/types'

import { AuthStore } from 'stores/AuthStore'
import { HeaderStore } from 'stores/HeaderStore'
import { createNavStore } from 'stores/NavStore'
import { SnapshotStore } from 'stores/SnapshotStore'
import { deleteDb, validateUserDbs } from 'utils/idbUtils'

import { preloadDatabase } from './dbPreloader'

let rootStore: IRootStore | undefined = undefined

interface ICreateRootStoreResult {
  rootStore: IRootStore
}

export const createRootStore = (): ICreateRootStoreResult => {
  if (rootStore) {
    return { rootStore }
  }
  const mediaConfig: IRemoteMediaStoreProps = {
    handleUploadFile: async ({ file, url }) =>
      fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      }),
    type: 'remote'
  }

  const NexusState = createNexusState({
    authConfig: new AuthStore(),
    mediaConfig,
    preloadDatabase,
    onLoginSuccess: validateUserDbs,
    dbConfig: {
      onDatabaseDestroyed: async dbName => {
        await deleteDb(`_pouch_idb-${dbName}`)
      },
      experimentalMainOnlyReplication: true,
      enableReplication: true,
      adapter: 'idb',
      extraPlugins: [{ name: 'idb', plugin: PouchIDB }]
    },
    snapshotConfig: new SnapshotStore()
  })

  const history = createBrowserHistory({ basename: '/web' })

  class RootStore extends NexusState {
    public headerStore: HeaderStore

    public history: History

    public navStore: INavStore

    public constructor() {
      super()
      this.history = history
      this.headerStore = new HeaderStore()
      this.navStore = createNavStore({ rootStore: this, history })
    }
  }
  rootStore = new RootStore()
  return { rootStore }
}

declare module '@inspectornexus/types' {
  interface IMediaStoreConfig extends IRemoteMediaStoreProps {}
}

declare module '@inspectornexus/react-hooks' {
  export interface IRootStore extends INexusState {
    headerStore: HeaderStore
    history: History
    initialized: boolean
    navStore: INavStore
  }
}
