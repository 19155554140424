import { observer } from 'mobx-react'
import type { ReactNode } from 'react'

import { Flex, Text } from '@inspectornexus/components'
import { useStoresSelector } from '@inspectornexus/react-hooks'
import { notEmptyString } from '@inspectornexus/utils'

import { appVersion } from 'config/constants'
import { loadable, withMinDelay } from 'utils/getLoadable'

const Header = loadable(
  async () => withMinDelay(import('components/Header'), 0),
  { resolveComponent: c => c.Header, displayName: 'Header' }
)
const PageHeader = loadable(
  async () => withMinDelay(import('components/PageHeader'), 0),
  { resolveComponent: c => c.PageHeader, displayName: 'PageHeader' }
)

interface IProps {
  isAuthenticated: boolean
  children: ReactNode
}

export const Layout = observer(
  ({ children, isAuthenticated }: IProps) => {
    const { headerStore, navStore } = useStoresSelector(s => ({
      headerStore: s.headerStore,
      navStore: s.navStore
    }))
    const displayHeader = isAuthenticated && navStore.currentHeaderVisible
    const currentHeaderTitle = navStore.currentHeaderTitle
    return (
      <>
        {displayHeader ? <Header /> : null}

        <Flex
          bg="wrapper"
          style={{ marginLeft: 'calc(100vw - 100%)' }}
          minWidth="400px"
          minHeight={
            displayHeader ? `calc(100vh - ${headerStore.height}px)` : '100vh'
          }
          justifyContent="center"
          alignItems="stretch"
        >
          <Flex
            width="100%"
            maxWidth={1200}
            px={3}
            style={{ margin: '0px auto' }}
            flexDirection="column"
          >
            {displayHeader && notEmptyString(currentHeaderTitle) ? (
              <PageHeader title={currentHeaderTitle} />
            ) : null}
            <Flex
              flex={displayHeader ? '0' : '1 0 auto'}
              justifyContent="space-between"
              flexWrap="wrap"
              alignItems="flex-start"
              flexDirection="row"
              style={{ marginLeft: '-0.75rem', marginRight: '-0.75rem' }}
            >
              {children}
            </Flex>
          </Flex>
          <Flex
            position="fixed"
            bottom={0}
            right={0}
            py={1}
            px={2}
            style={{ pointerEvents: 'none' }}
          >
            <Text color="inactive" style={{ fontSize: '9px' }}>
              v{appVersion}
            </Text>
          </Flex>
        </Flex>
      </>
    )
  },
  { displayName: 'Layout' }
)
