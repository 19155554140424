import { createRoot } from 'react-dom/client'

import { InitApp } from './InitApp'
import { registerServiceWorker } from './serviceWorker'

const main = () => {
  if (process.env.NODE_ENV === 'production') {
    // for (const k of ObjectKeys(console)) {
    //   // eslint-disable-next-line no-console
    //   console[k as 'log'] = () => undefined
    // }
    registerServiceWorker()
  }

  const rootElement = document.getElementById('root')
  if (!rootElement) {
    throw new Error('Failed to load.')
  }

  const root = createRoot(rootElement)
  root.render(<InitApp />)
}

main()
