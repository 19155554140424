import type { IDbStore } from 'utils/idbUtils'
import { createDb } from 'utils/idbUtils'

interface ISnapshotStoreConfig {
  deleteSnapshot: (key: string) => Promise<void>
  readSnapshot: (key: string) => Promise<string | undefined>
  writeSnapshot: (p: { data: string; key: string }) => Promise<void>
}

export class SnapshotStore implements ISnapshotStoreConfig {
  private _idbStore: IDbStore<'snapshot-store'> | undefined

  private readonly getDbStore = async () => {
    if (!this._idbStore) {
      this._idbStore = await createDb({
        schemas: [{ schemaName: 'snapshot-store' }],
        version: 1,
        dbName: 'snapshot'
      })
    }

    return this._idbStore
  }

  public deleteSnapshot = async (key: string): Promise<void> => {
    const dbStore = await this.getDbStore()
    return dbStore.del({ key })
  }

  public readSnapshot = async (key: string): Promise<string | undefined> => {
    const dbStore = await this.getDbStore()
    return dbStore.get({ key })
  }

  public writeSnapshot = async ({
    key,
    data
  }: {
    data: string
    key: string
  }): Promise<void> => {
    const dbStore = await this.getDbStore()
    return dbStore.set({ key, value: data })
  }
}
