import delayPromise from 'delay'

import type {
  DefaultComponent,
  LoadableComponent,
  OptionsWithoutResolver,
  OptionsWithResolver
} from '@loadable/component'
import baseLoadable from '@loadable/component'

declare function loadableFunction<Props, Module = DefaultComponent<Props>>(
  loadFunction: (props: Props) => Promise<Module>,
  options: OptionsWithResolver<Props, Module>
): LoadableComponent<Props>

declare function loadableFunction<Props>(
  loadFunction: (props: Props) => Promise<DefaultComponent<Props>>,
  options?: OptionsWithoutResolver<Props>
): LoadableComponent<Props>

const pMinDelay = async <F,>(thisPromise: Promise<F>, minDelay: number) => {
  const value = await Promise.all([thisPromise, delayPromise(minDelay)])
  return value[0]
}

export const withMinDelay = async <F,>(
  providedFunction: Promise<F>,
  delay: number
) => (delay > 0 ? pMinDelay(providedFunction, delay) : providedFunction)

type LoadableParams = Parameters<typeof loadableFunction>

export const loadable: typeof loadableFunction = (
  loadFunction: LoadableParams[0],
  opts: LoadableParams[1]
) =>
  baseLoadable(loadFunction, {
    ...opts,
    displayName: opts?.displayName ?? '',
    fallback: opts?.fallback ?? <div style={{ display: 'none' }} />
  })
