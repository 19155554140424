import { memo, useRef } from 'react'

import type { ReactNode } from 'react'

import type { IRootStore } from '@inspectornexus/react-hooks'
import { storesContext } from '@inspectornexus/react-hooks'

interface IProps {
  rootStore: IRootStore
  children: ReactNode
}
export const RootStoreProvider = memo<IProps>(({ children, rootStore }) => {
  const stores = useRef({ rootStore })
  return (
    <storesContext.Provider value={stores.current}>
      {children}
    </storesContext.Provider>
  )
})

RootStoreProvider.displayName = 'RootStoreProvider'
