import { pouchDbSchemas } from '@inspectornexus/constants'
import type { PreloadDatabaseFunction } from '@inspectornexus/db'
import type {
  AnyObject,
  FetchSession,
  IFetchBasicSession,
  IndexedDbDatabase,
  IUserSession,
  ProgressNotifier
} from '@inspectornexus/types'
import {
  generateRevTree,
  getCompressorForDb,
  isObjectLoose,
  makeAnalyticsFunctions,
  notEmptyString,
  safeStringify,
  sleepFor
} from '@inspectornexus/utils'

import { createDb } from 'utils/idbUtils'
const makeAnalyticsForFunction = makeAnalyticsFunctions({
  moduleName: 'preloadDatabase'
})

const isBasicFetchSession = (
  session: FetchSession
): session is IFetchBasicSession =>
  notEmptyString((session as IFetchBasicSession).token)

const parseFetchSession = (session: FetchSession): string =>
  isBasicFetchSession(session)
    ? `Bearer ${session.token}:${session.password}`
    : session.authorization
const stringifyDoc = <T extends AnyObject>(doc: T) => {
  const docString = safeStringify(doc)
  return docString.replaceAll("'", "''")
}

const createAndPopulateDatabase = async ({
  db,
  dbName,
  progressNotifier
}: {
  db: IndexedDbDatabase
  dbName: string
  progressNotifier: ProgressNotifier<'loadInitialWithFetch'>
}) => {
  const batchesCnt = db.dataBatches.length
  await progressNotifier.update({
    taskId: 'loadInitialWithFetch',
    message: `Importing data [0/${batchesCnt + 3}]...`,
    complete: 0,
    total: batchesCnt
  })
  const c = getCompressorForDb(dbName)!
  const idb = await createDb({
    dbName: db.dbName,
    version: db.version,
    schemas: pouchDbSchemas
  })

  let seq = 0

  for (let batchIndex = 0; batchIndex < batchesCnt; batchIndex++) {
    const batch = db.dataBatches[batchIndex]!

    const t1 = idb.startTransaction(
      ['document-store', 'by-sequence'],
      'readwrite'
    )
    const docStore = t1.trx.objectStore('document-store')
    const bySeqStore = t1.trx.objectStore('by-sequence')

    // const isLastBatch = batchIndex === batchesCnt - 1

    const batchSize = batch.length

    for (let index = 0; index < batchSize; index++) {
      const compressedDoc = batch[index]!
      // const isLastDoc = index === batchSize - 1
      const currentSeq = seq + 1
      seq = currentSeq
      const doc = c.decompressRecord(compressedDoc)
      // eslint-disable-next-line @typescript-eslint/no-array-delete
      delete batch[index]

      if (!doc) {
        continue
      }

      const revTree = generateRevTree(doc)
      const docMetadata = {
        id: doc._id,
        rev: doc._rev,
        rev_tree: revTree,
        winningRev: doc._rev,
        deleted: false,
        seq: currentSeq
      } as Record<string, unknown> | undefined
      const docStoreRecord = {
        data: stringifyDoc(docMetadata!),
        winningRev: doc._rev,
        deletedOrLocal: '0',
        seq: currentSeq,
        id: doc._id
      } as Record<string, unknown> | undefined
      docStore.put(docStoreRecord)
      const seqStoreRecord = {
        ...doc,
        _doc_id_rev: `${doc._id}::${doc._rev}`
      }
      bySeqStore.put(seqStoreRecord, currentSeq)
    }
    // eslint-disable-next-line @typescript-eslint/no-array-delete
    delete db.dataBatches[batchIndex]
    await t1.commit()
    await progressNotifier.update({
      taskId: 'loadInitialWithFetch',
      message: `Importing data (${batchIndex + 1}/${batchesCnt + 3})...`
    })
  }
  await progressNotifier.update({
    taskId: 'loadInitialWithFetch',
    message: `Importing data (${batchesCnt}/${batchesCnt + 3})...`
  })
  const t3 = idb.startTransaction('local-store', 'readwrite')

  const localStore = t3.trx.objectStore('local-store')
  for (const doc of db.meta.localStore) {
    localStore.put(doc)
  }
  t3.trx.commit()
  await progressNotifier.update({
    taskId: 'loadInitialWithFetch',
    message: `Importing data (${batchesCnt + 2}/${batchesCnt + 3})...`
  })
  const t4 = idb.startTransaction('meta-store', 'readwrite')
  const metaStore = t4.trx.objectStore('meta-store')
  metaStore.put(db.meta.metaStore)
  await t4.commit()
  idb.close()
}

export const preloadDatabase: PreloadDatabaseFunction = async ({
  remoteDbName,
  progressNotifier,
  session
}) => {
  const { captureError } = makeAnalyticsForFunction({
    functionName: 'preloadDatabase'
  })
  if (!isObjectLoose<IUserSession>(session)) {
    return false
  }
  let progressEnabled = true
  let currentComplete = 0
  const updateFakeProgress = async () => {
    if (!progressEnabled || currentComplete >= 35) {
      return
    }
    currentComplete += 1
    await progressNotifier.update({
      taskId: 'loadInitialWithFetch',
      message: 'Downloading data...',
      complete: currentComplete,
      total: 50
    })
    await sleepFor(1000)
    void updateFakeProgress()
  }
  void updateFakeProgress()
  const res = await fetch(
    `https://api.inspectornexus.com/db/buildIdb?dbName=${encodeURIComponent(
      remoteDbName
    )}`,
    {
      method: 'GET',
      headers: {
        Authorization: parseFetchSession(session)
      }
    }
  )
  progressEnabled = false
  if (res.status !== 200) {
    const text = await res.text()
    captureError({ message: `[${res.status}]: ${text}` })
    return false
  }
  await progressNotifier.update({
    taskId: 'loadInitialWithFetch',
    message: 'Extracting data...'
  })
  let db = await (res.json() as Promise<IndexedDbDatabase> | undefined)
  if (!db) {
    return false
  }
  await sleepFor(10)
  await createAndPopulateDatabase({
    progressNotifier,
    db,
    dbName: remoteDbName
  })
  db = undefined
  await progressNotifier.update({
    taskId: 'loadInitialWithFetch',
    message: 'Finishing up...',
    complete: 49,
    total: 50
  })
  await sleepFor(10)
  await progressNotifier.update({
    taskId: 'loadInitialWithFetch',
    done: true
  })
  return true
}
