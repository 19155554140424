import { memo, StrictMode, useEffect, useMemo, useRef } from 'react'

import { Router } from 'react-router'

import { ThemeProvider } from '@emotion/react'
import { CssReset, LoadingScreen } from '@inspectornexus/components'
import type { IRootStore } from '@inspectornexus/react-hooks'
import { useStateChecked, useStateWithRef } from '@inspectornexus/react-hooks'
import { theme } from '@inspectornexus/theme'
import { makeAnalyticsFunctions, parseError } from '@inspectornexus/utils'

import {
  BrowserCompatError,
  BrowserOutdatedError
} from 'components/BrowserError'
import { App } from 'containers/App'
import { createRootStore, RootStoreProvider } from 'stores/RootStore'
import { testBrowserCompatibility } from 'utils/checkBrowserCompat'
import { initModules } from 'utils/initModules'

interface IChildProps {
  browserIncompatible: boolean
  browserOutdated: boolean
  rootStore?: IRootStore
}

const InitChild = memo<IChildProps>(
  ({ rootStore, browserIncompatible, browserOutdated }) => {
    if (browserIncompatible) {
      return <BrowserCompatError />
    }
    if (browserOutdated) {
      return <BrowserOutdatedError />
    }
    if (!rootStore) {
      return <LoadingScreen />
    }
    return (
      <Router history={rootStore.history}>
        <StrictMode>
          <RootStoreProvider rootStore={rootStore}>
            <App />
          </RootStoreProvider>
        </StrictMode>
      </Router>
    )
  }
)

InitChild.displayName = 'InitChild'

export const InitApp = memo(() => {
  const makeAnalyticsForFunction = makeAnalyticsFunctions({
    moduleName: 'InitApp'
  })
  const [rootStore, handleSetRootStore, rootStoreRef] = useStateWithRef<
    IRootStore | undefined
  >(undefined)
  const [browserOutdated, handleSetHasBrowserOutdated] = useStateChecked(false)
  const [browserIncompatible, handleSetBrowserIncompatible] =
    useStateChecked(false)
  const initialized = useRef(false)
  const isInitializing = useRef(false)

  const initApp = async () => {
    const { captureError } = makeAnalyticsForFunction({
      functionName: 'initApp'
    })
    if (isInitializing.current || rootStoreRef.current) {
      return
    }
    isInitializing.current = true
    try {
      await testBrowserCompatibility()
    } catch {
      isInitializing.current = false
      return handleSetBrowserIncompatible(true)
    }
    const nextStores = createRootStore()
    try {
      if (!initialized.current) {
        initModules()
        await nextStores.rootStore.init()

        nextStores.rootStore.navStore.init()
        initialized.current = true
      }

      handleSetRootStore(nextStores.rootStore)
    } catch (baseError: unknown) {
      const error = parseError(baseError as Error)
      captureError({
        message: 'Got error when rendering app',
        error
      })
      handleSetHasBrowserOutdated(true)
    }
    isInitializing.current = false
  }

  useEffect(() => void initApp(), [])

  return useMemo(
    () => (
      <ThemeProvider theme={theme}>
        <>
          <CssReset />
          <InitChild
            rootStore={rootStore}
            browserIncompatible={browserIncompatible}
            browserOutdated={browserOutdated}
          />
        </>
      </ThemeProvider>
    ),
    [!!browserIncompatible, !!browserOutdated, !!rootStore]
  )
})

InitApp.displayName = 'InitApp'
