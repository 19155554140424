import styled from '@emotion/styled'
import { Button, Heading, Text } from '@inspectornexus/components'
import { AlertTriangle } from '@inspectornexus/icons'

import { BROWSER_UPDATE_URL, SUPPORT_EMAIL } from 'config/constants'

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  ${({ theme }) => `background-color:${theme.colors.wrapper}`};
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  width: 500px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 4px;
  overflow: visible;
  ${({ theme }) => `background-color:${theme.colors.container}`};
`

const InnerContainer = styled.div`
  width: 80%;
  position: relative;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
`

const gotoBrowserUpdate = () => {
  document.location.href = BROWSER_UPDATE_URL
}
const contactSupport = () => {
  document.location.href = `mailto:${SUPPORT_EMAIL}`
}

export const BrowserOutdatedError = () => (
  <Wrapper>
    <Container>
      <AlertTriangle size={55} color="caution" style={{ height: '48px' }} />
      <InnerContainer>
        <Heading as="h1" mt={2}>
          Your Browser Is Outdated
        </Heading>
        <Text style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
          Your browser must be updated to use Inspector Nexus. Please click
          below for more information.
        </Text>
        <Button bg="brand" mt={3} onClick={gotoBrowserUpdate}>
          <Text color="inherit">Check For Updates</Text>
        </Button>
        <Button
          ml={3}
          bg="container"
          color="text"
          mt={3}
          onClick={contactSupport}
        >
          <Text color="inherit">Contact Support</Text>
        </Button>
      </InnerContainer>
    </Container>
  </Wrapper>
)

export const BrowserCompatError = () => (
  <Wrapper>
    <Container>
      <AlertTriangle size={55} color="caution" style={{ height: '48px' }} />
      <InnerContainer>
        <Heading as="h1" mt={2}>
          Incompatible Browser Settings
        </Heading>
        <Text
          mt={2}
          fontSize={1}
          textAlign="left"
          style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
        >
          Your browser settings are incompatible with Inspector Nexus. The most
          common cause of this is Private Browsing Mode.
        </Text>
        <Text
          fontSize={1}
          textAlign="left"
          mt={2}
          style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
        >
          Please disable Private Browsing Mode and try again, or click below to
          see if your browser needs to be updated.
        </Text>
        <Button bg="brand" mt={3} onClick={gotoBrowserUpdate}>
          <Text color="light">Check For Updates</Text>
        </Button>
        <Button ml={3} bg="container" mt={3} onClick={contactSupport}>
          <Text color="brand">Contact Support</Text>
        </Button>
      </InnerContainer>
    </Container>
  </Wrapper>
)
