import { observer } from 'mobx-react'
import { createPortal } from 'react-dom'

import { Flex, ProgressSpinner, Text } from '@inspectornexus/components'
import { useStoresSelector } from '@inspectornexus/react-hooks'
import type { InitTaskPublicProgress } from '@inspectornexus/types'
import { formatSpeed } from '@inspectornexus/utils'

interface IProps {
  currentProgress?: InitTaskPublicProgress
  message?: string
  totalProgress?: number
}

export const LoadingScreenWithNav = observer(
  ({ currentProgress, totalProgress, message }: IProps) => {
    const { headerStore } = useStoresSelector(s => ({
      headerStore: s.headerStore
    }))

    return createPortal(
      <Flex
        top={headerStore.height}
        left={0}
        position="absolute"
        justifyContent="center"
        alignItems="center"
        height={`calc(100% - ${headerStore.height}px)`}
        width="100%"
        zIndex={999}
        bg="wrapper"
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <ProgressSpinner
            progress={totalProgress}
            label={message ?? currentProgress?.message}
          />

          {currentProgress?.speed === undefined ? null : (
            <Text mt={1} fontSize={0} color="text2">
              {formatSpeed(currentProgress.speed)}
            </Text>
          )}
        </Flex>
      </Flex>,
      document.body
    )
  },
  { displayName: 'LoadingScreenWithNav' }
)
