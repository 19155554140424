import { memo } from 'react'

import { Observer } from 'mobx-react'
import type { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { useComputed, useStoresSelector } from '@inspectornexus/react-hooks'
import { notEmptyString } from '@inspectornexus/utils'

import { Layout } from 'components/Layout'
import { EmailConfirmedPage } from 'pages/EmailConfirmedPage'
import { ResetPasswordPage } from 'pages/ResetPasswordPage'
import { loadable, withMinDelay } from 'utils/getLoadable'

const AuthApp = loadable(async () => withMinDelay(import('./AuthApp'), 0), {
  resolveComponent: c => c.AuthApp,
  displayName: 'AuthApp'
})
const NoAuthApp = loadable(async () => withMinDelay(import('./NoAuthApp'), 0), {
  resolveComponent: c => c.NoAuthApp,
  displayName: 'NoAuthApp'
})

const RootContainer = styled.div<{ modalOpen: boolean }>`
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  ${({ modalOpen }) =>
    modalOpen
      ? css`
          -webkit-overflow-scrolling: auto;
          overflow-y: hidden;
          filter: blur(-2px);
        `
      : css`
          overflow-y: overlay;
          -webkit-overflow-scrolling: touch;
        `};
`

RootContainer.displayName = 'RootContainer'

const modalOpenStyles = css`
  body {
    overflow-y: hidden;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    position: fixed;
  }
`
const modalClosedStyles = css``

export const AppContainer = memo(() => {
  const { authStore, navStore } = useStoresSelector(s => ({
    authStore: s.authStore,
    navStore: s.navStore
  }))

  const hasHandoff = useComputed('AppContainer/hasHandoff', () => {
    const currentRouteData = navStore.currentRouteData
    if (currentRouteData?.pathName !== '/') {
      return false
    }
    return notEmptyString(
      currentRouteData.query?.handoffv2 ?? currentRouteData.query?.handoff
    )
  })
  const renderEmailConfirmedPage = (p: RouteComponentProps) => (
    <EmailConfirmedPage {...p} />
  )
  const renderResetPasswordPage = (p: RouteComponentProps) => (
    <ResetPasswordPage {...p} />
  )

  return (
    <Observer name="AppContainer">
      {() => {
        const handoff = hasHandoff.get()

        return (
          <RootContainer modalOpen={navStore.modalIsOpen}>
            <Global
              styles={
                navStore.modalIsOpen ? modalOpenStyles : modalClosedStyles
              }
            />
            <Layout isAuthenticated={authStore?.authenticated ?? false}>
              <Switch>
                <Route
                  path="/emailConfirmed"
                  exact
                  render={renderEmailConfirmedPage}
                />
                <Route
                  path="/resetPassword"
                  exact
                  render={renderResetPasswordPage}
                />
                {authStore?.authenticated ? (
                  <AuthApp handoff={handoff} />
                ) : (
                  <NoAuthApp />
                )}
              </Switch>
            </Layout>
          </RootContainer>
        )
      }}
    </Observer>
  )
})

AppContainer.displayName = 'AppContainer'
