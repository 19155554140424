import { memo } from 'react'

import type { RouteComponentProps } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import { isEmptyString, parseQuery } from '@inspectornexus/utils'
import loadable from '@loadable/component'

import { LoadingScreenWithNav } from 'components/LoadingScreenWithNav'
import { withMinDelay } from 'utils/getLoadable'

const EmailConfirmed = loadable(
  async () =>
    withMinDelay(import('containers/EmailConfirmed/EmailConfirmed'), 0),
  {
    resolveComponent: c => c.EmailConfirmed,
    fallback: <LoadingScreenWithNav />,
    displayName: 'EmailConfirmed'
  }
)

export const EmailConfirmedPage = memo<RouteComponentProps>(p => {
  const query = parseQuery<keyof IEmailConfirmedQueryParams>(location.search)
  if (isEmptyString(query.success)) {
    return <Redirect to="/" />
  }
  return <EmailConfirmed {...p} />
})

EmailConfirmedPage.displayName = 'EmailConfirmedPage'
