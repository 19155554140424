import { pouchDbSchemas } from '@inspectornexus/constants'
import type { IndexedDbSchema, IUserSession } from '@inspectornexus/types'
import {
  makeAnalyticsFunctions,
  parseError,
  sleepFor,
  trackedSetTimeout
} from '@inspectornexus/utils'
const makeAnalyticsForFunction = makeAnalyticsFunctions({
  moduleName: 'idbUtils'
})

interface IWaitForCompleteRequest<ResultType> {
  addEventListener: (t: string, callback: (error?: unknown) => void) => void
  removeEventListener: (t: string, callback: (error?: unknown) => void) => void
  result?: ResultType
  readyState?: string
}
interface IWaitForCompleteParams<ResultType> {
  request: IWaitForCompleteRequest<ResultType>
  timeout?: number
}

const waitForIdbRequest = async <ResultType>(
  t: IWaitForCompleteParams<ResultType>
): Promise<ResultType> => {
  const { request, timeout } = t
  if (request.readyState === 'done') {
    return request.result as ResultType
  }
  let hasResolved = false
  const requestPromise = new Promise<ResultType>((resolve, reject) => {
    const handleSuccess = () => {
      if (hasResolved) {
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      removeEventListeners()
      hasResolved = true
      resolve(request.result as ResultType)
    }
    const handleError = (error: unknown) => {
      if (hasResolved) {
        return
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      removeEventListeners()
      hasResolved = true
      reject(error as Error)
    }

    const removeEventListeners = () => {
      request.removeEventListener('abort', handleError)
      request.removeEventListener('error', handleError)
      request.removeEventListener('complete', handleSuccess)
      request.removeEventListener('success', handleSuccess)
      request.removeEventListener('close', handleSuccess)
    }

    const checkStatus = async (): Promise<void> => {
      if (hasResolved) {
        return
      }
      if (request.readyState === 'done') {
        return handleSuccess()
      }
      await sleepFor(1000)
      return checkStatus()
    }

    request.addEventListener('abort', handleError)
    request.addEventListener('error', handleError)
    request.addEventListener('complete', handleSuccess)
    request.addEventListener('success', handleSuccess)
    request.addEventListener('close', handleSuccess)
    void checkStatus()
  })
  if (timeout === undefined) {
    return requestPromise
  }
  const timeoutPromise = new Promise<ResultType | undefined>(
    (resolve, reject) => {
      const handleResolve = () => {
        if (hasResolved) {
          return resolve(undefined)
        }
        hasResolved = true
        return reject(
          new Error(`Request timed out! readyState: ${request.readyState}`)
        )
      }
      trackedSetTimeout(() => handleResolve(), timeout)
    }
  )
  return Promise.race([timeoutPromise, requestPromise]) as Promise<ResultType>
}

interface IStartedTransaction {
  commit: () => Promise<void>
  trx: IDBTransaction
}

export interface IDbStore<SchemaNames extends string> {
  startTransaction: <S extends SchemaNames>(
    storeNames: S | S[],
    txMode: IDBTransactionMode
  ) => IStartedTransaction
  get: <V, S extends SchemaNames = SchemaNames>(
    p: IDbOperationParams<S>
  ) => Promise<V>
  set: <S extends SchemaNames>(p: ISetParams<S>) => Promise<void>
  del: <S extends SchemaNames>(p: IDbOperationParams<S>) => Promise<void>
  close: () => void
}
interface IDbOperationParams<SchemaName extends string> {
  key: string
  schemaName?: SchemaName
  timeout?: number
}
interface ISetParams<SchemaName extends string>
  extends IDbOperationParams<SchemaName> {
  value: unknown
}

interface ICreateDbParams<SchemaNames extends string> {
  dbName: string
  schemas: Array<IndexedDbSchema<SchemaNames>>
  version: number
}

export const createDb = async <SchemaNames extends string>(
  p: ICreateDbParams<SchemaNames>
): Promise<IDbStore<SchemaNames>> => {
  const getDb = async () => {
    try {
      const dbRequest = indexedDB.open(p.dbName, p.version)

      dbRequest.onupgradeneeded = () => {
        const idb = dbRequest.result
        for (const schema of p.schemas) {
          const store = idb.createObjectStore(
            schema.schemaName,
            schema.schemaParams
          )
          for (const index of schema.indices ?? []) {
            store.createIndex(index.keyPath, index.keyPath, index.indexParams)
          }
        }
      }

      return await waitForIdbRequest({ request: dbRequest, timeout: 5000 })
    } catch (error: unknown) {
      throw error as Error
    }
  }
  const db = await getDb()
  const defaultSchemaName = p.schemas[0]!.schemaName

  const startTransaction = <S extends SchemaNames>(
    storeNames: S | S[],
    txMode: IDBTransactionMode
  ) => {
    const trx = db.transaction(storeNames, txMode, {
      durability: 'relaxed'
    })
    const commit = async () => {
      try {
        trx.commit()
        await waitForIdbRequest({ request: trx })
      } catch {}
    }
    return { commit, trx }
  }

  const openObjectStore = <S extends SchemaNames>(
    storeName: S,
    txMode: IDBTransactionMode
  ) => {
    const { trx } = startTransaction(storeName, txMode)
    return trx.objectStore(storeName)
  }

  const get = async <V, S extends SchemaNames = SchemaNames>({
    key,
    timeout,
    schemaName
  }: IDbOperationParams<S>): Promise<V> => {
    const store = openObjectStore(schemaName ?? defaultSchemaName, 'readonly')
    const request = store.get(key)
    return waitForIdbRequest<V>({ request, timeout })
  }

  const set = async <S extends SchemaNames>({
    key,
    timeout,
    schemaName,
    value
  }: ISetParams<S>): Promise<void> => {
    const store = openObjectStore(schemaName ?? defaultSchemaName, 'readwrite')
    store.put(value, key)
    const request = store.transaction
    return waitForIdbRequest({ request, timeout })
  }

  const del = async <S extends SchemaNames>({
    key,
    timeout,
    schemaName
  }: IDbOperationParams<S>): Promise<void> => {
    const store = openObjectStore(schemaName ?? defaultSchemaName, 'readwrite')
    store.delete(key)
    const request = store.transaction
    return waitForIdbRequest({ request, timeout })
  }

  const close = () => {
    db.close()
  }

  return { close, startTransaction, get, set, del }
}

export const deleteDb = async (dbName: string): Promise<void> => {
  const { captureBreadcrumb } = makeAnalyticsForFunction({
    functionName: 'deleteDb'
  })
  try {
    captureBreadcrumb(dbName)
    const deleteRequest = indexedDB.deleteDatabase(dbName)

    await waitForIdbRequest({ request: deleteRequest, timeout: 10_000 })
  } catch {}
}

const validateDb = async (dbName: string) => {
  const { captureError } = makeAnalyticsForFunction({
    functionName: 'validateDb'
  })
  try {
    const request = indexedDB.open(dbName, 5)
    request.onupgradeneeded = () => {
      const idb = request.result
      for (const schema of pouchDbSchemas) {
        const store = idb.createObjectStore(
          schema.schemaName,
          schema.schemaParams
        )
        for (const index of schema.indices ?? []) {
          store.createIndex(index.keyPath, index.keyPath, index.indexParams)
        }
      }
    }

    const db = await waitForIdbRequest({ request, timeout: 10_000 })
    db.close()

    return db.version === 5 && db.objectStoreNames.length === 7
  } catch (baseError: unknown) {
    const error = parseError(baseError as Error)
    captureError({ message: dbName, error })
  }
  return false
}

export const validateUserDbs = async (
  p: Pick<IUserSession, 'userDBs'>
): Promise<void> => {
  const { captureBreadcrumb } = makeAnalyticsForFunction({
    functionName: 'validateUserDbs'
  })
  const userDbs = Object.values(p.userDBs)
  for (const dbUrl of userDbs) {
    const sanitizedDbName = dbUrl
      .split('/')
      .pop()!
      .replaceAll('$', '-')
      .replaceAll(/\([^)]+\)/g, '')
    const dbName = `idb-${sanitizedDbName}`
    const idbName = `_pouch_${dbName}`
    const isOk = await validateDb(idbName)
    captureBreadcrumb(`${dbName} isValid? ${isOk}`)
    if (isOk) {
      continue
    }
    await deleteDb(idbName)
  }
}
