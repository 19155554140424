import type {
  FetchClientFunction,
  IFetchClientProps,
  IFetchResponse
} from '@inspectornexus/types'

export const fetchClient: FetchClientFunction = async <T>({
  body,
  method,
  url,
  headers
}: IFetchClientProps): Promise<IFetchResponse<T>> =>
  fetch(url, {
    method,
    body,
    headers
  })
