import { configure } from 'mobx'

import superlogin from '@axxag/superlogin-client'
import { init } from '@inspectornexus/core'
import {
  addBreadcrumb,
  browserTracingIntegration,
  captureException,
  init as initSentryFunction,
  setTag,
  setUser
} from '@sentry/browser'

import { isDev, superloginConfig } from 'config/constants'

import { fetchClient } from './fetchClient'

const initMobx = (): void => {
  if (!isDev) {
    return
  }

  configure({
    isolateGlobalState: true,
    enforceActions: 'observed',
    computedRequiresReaction: false,
    reactionRequiresObservable: false,
    observableRequiresReaction: false,
    disableErrorBoundaries: false
  })
}

const initSentry = () => {
  initSentryFunction({
    dsn: 'https://fde0eea4191a41909290771462a7e830@o117672.ingest.sentry.io/6699136',
    integrations: [browserTracingIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
    environment: isDev ? 'dev' : 'production',
    enabled: !isDev
  })

  setTag('module', 'nexus-webapp')
}

const initInspectorNexus = () =>
  init({
    addBreadcrumb,
    captureException,
    debug: isDev,
    disableEnvCheck: !isDev,
    fetchClient,
    setUser
  })

const initSuperlogin = () => superlogin.configure(superloginConfig)

export const initModules = (): void => {
  initSentry()
  initInspectorNexus()
  initSuperlogin()
  initMobx()
}
