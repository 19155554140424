import { memo } from 'react'

import type { RouteComponentProps } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import { isEmptyString, parseQuery } from '@inspectornexus/utils'
import loadable from '@loadable/component'

import { LoadingScreenWithNav } from 'components/LoadingScreenWithNav'
import { withMinDelay } from 'utils/getLoadable'

const ResetPassword = loadable(
  async () => withMinDelay(import('containers/ResetPassword/ResetPassword'), 0),
  {
    resolveComponent: c => c.ResetPassword,
    fallback: <LoadingScreenWithNav />,
    displayName: 'ResetPassword'
  }
)

export const ResetPasswordPage = memo<RouteComponentProps>(p => {
  const query = parseQuery<keyof IResetPasswordQueryParams>(location.search)
  if (isEmptyString(query.token)) {
    return <Redirect to="/" />
  }
  return <ResetPassword token={query.token} {...p} />
})

ResetPasswordPage.displayName = 'ResetPasswordPage'
