import { makeAnalyticsFunctions, parseError } from '@inspectornexus/utils'

import { createDb, deleteDb } from 'utils/idbUtils'
const makeAnalyticsForFunction = makeAnalyticsFunctions({
  moduleName: 'checkBrowserCompat'
})

const checkLocalStorageCompat = () => {
  if (typeof localStorage !== 'object') {
    return
  }
  // Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
  // throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
  // to avoid the entire page breaking, without having to do a check at each usage of Storage.
  const testValueKey = 'localStorage'
  const testValueActual = '1'

  localStorage.setItem(testValueKey, testValueActual)
  const testValue = localStorage.getItem(testValueKey)
  if (testValue !== testValueActual) {
    throw new Error('LocalStorage test failed!')
  }
  localStorage.removeItem(testValueKey)
  const testValue2 = localStorage.getItem(testValueKey)
  if (testValue2 !== null) {
    throw new Error('LocalStorage test failed!')
  }
}
const checkIdbCompat = async () => {
  const { captureBreadcrumb } = makeAnalyticsForFunction({
    functionName: 'checkIdbCompat'
  })
  const dbName = 'idbCompat'
  await deleteDb(dbName)
  const idbStore = await createDb({
    dbName,
    version: 1,
    schemas: [{ schemaName: 'idbCompatStore' }]
  })
  try {
    const testKey = 'testValue'
    const testValue = 10_203
    captureBreadcrumb('1')

    await idbStore.set({
      key: testKey,
      value: testValue
    })
    captureBreadcrumb('2')
    const idbValue1 = await idbStore.get<number>({
      key: testKey
    })
    if (idbValue1 !== testValue) {
      throw new Error('Idb test failed!')
    }
    captureBreadcrumb('3')
    await idbStore.del({ key: testKey })
    captureBreadcrumb('4')
    const idbValue2 = await idbStore.get<number | undefined>({
      key: testKey
    })
    if (idbValue2 !== undefined) {
      throw new Error('Idb test failed!')
    }
  } catch (error: unknown) {
    idbStore.close()
    throw error as Error
  }
}

export const testBrowserCompatibility = async (): Promise<void> => {
  const { captureBreadcrumb, captureError } = makeAnalyticsForFunction({
    functionName: 'testBrowserCompatibility'
  })
  try {
    captureBreadcrumb('Checking local storage...')
    checkLocalStorageCompat()
    captureBreadcrumb('Checking indexeddb...')
    await checkIdbCompat()
  } catch (baseError: unknown) {
    const error = parseError(baseError)
    captureError({
      error,
      message: JSON.stringify(baseError)
    })
    throw error as Error
  }
}
