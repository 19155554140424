import packageJson from '../../package.json'

let BASE_API_URL = undefined
let BASE_DEV_EMAIL = undefined
let BASE_DEV_PWD = undefined
let BASE_NODE_ENV = 'development'
let BASE_STRIPE_PRIVATE_KEY = undefined
let BASE_GOOGLE_API_KEY = undefined
// eslint-disable-next-line unicorn/no-typeof-undefined
if (typeof import.meta.env === 'undefined') {
  BASE_API_URL = process.env.API_URL
  BASE_DEV_EMAIL = process.env.DEV_EMAIL
  BASE_DEV_PWD = process.env.DEV_PWD
  BASE_GOOGLE_API_KEY = process.env.GOOGLE_API_KEY
  BASE_NODE_ENV = process.env.NODE_ENV as 'development' | 'production'
  BASE_STRIPE_PRIVATE_KEY = process.env.STRIPE_PRIVATE_KEY
} else {
  BASE_API_URL = import.meta.env.VITE_API_URL
  BASE_DEV_EMAIL = import.meta.env.VITE_DEV_EMAIL
  BASE_DEV_PWD = import.meta.env.VITE_DEV_PWD
  BASE_GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY
  BASE_NODE_ENV = import.meta.env.PROD ? 'production' : 'development'
  BASE_STRIPE_PRIVATE_KEY = import.meta.env.VITE_STRIPE_PRIVATE_KEY
}

const API_URL = BASE_API_URL
const GOOGLE_API_KEY = BASE_GOOGLE_API_KEY
const STRIPE_PRIVATE_KEY = BASE_STRIPE_PRIVATE_KEY
export const DEV_EMAIL = BASE_DEV_EMAIL
export const DEV_PWD = BASE_DEV_PWD
export const isDev = BASE_NODE_ENV === 'development'
export const appVersion = packageJson.version

export const BROWSER_UPDATE_URL =
  'https://browser-update.org/update-browser.html'
export const SUPPORT_EMAIL = 'support@inspectornexus.com'
export const SAMPLE_PORCH_GOLD_CONTRACT_URL =
  'https://contract.inspectornexus.com/?token=Zz1zYW1wbGUmaT1zYW1wbGVDb250cmFjdFBvcmNo '
export const PORCH_GOLD_SUPPORT_EMAIL = 'inspectors@porch.com'

export const allConstants = {
  API_URL,
  BROWSER_UPDATE_URL,
  DEV_EMAIL,
  DEV_PWD,
  NODE_ENV: BASE_NODE_ENV,
  GOOGLE_API_KEY,
  PORCH_GOLD_SUPPORT_EMAIL,
  SAMPLE_PORCH_GOLD_CONTRACT_URL,
  STRIPE_PRIVATE_KEY,
  SUPPORT_EMAIL
}

export const superloginConfig = {
  serverUrl: API_URL,
  baseUrl: '/auth',
  socialUrl: `${API_URL}/auth`,
  noDefaultEndpoint: false,
  storage: 'local',
  providers: ['apple', 'google'],
  checkExpired: false,
  refreshThreshold: 0.5,
  timeout: 0
}
