import superlogin from '@axxag/superlogin-client'
import type { IAuthConfig } from '@inspectornexus/state'
import type { IUserSession } from '@inspectornexus/types'

export class AuthStore
  implements
    Omit<IAuthConfig, 'onTriggerAlert' | 'onUpdateEmail' | 'snapshotConfig'>
{
  public readonly signupSource = 'web'

  public onLogout = async (_dispose: boolean): Promise<void> =>
    Promise.resolve(undefined)

  public onSigninWithApple = async (): Promise<
    { session: IUserSession } | undefined
  > => {
    const session = (await superlogin.socialAuth('apple')) as IUserSession
    return { session }
  }

  public onSigninWithGoogle = async (): Promise<
    { session: IUserSession } | undefined
  > => {
    const session = (await superlogin.socialAuth('google')) as IUserSession
    return { session }
  }
}
